import { useMatches } from "@remix-run/react";
import { produce } from "immer";

export function useThingFromMatches(
	thing: string,
	options: {
		transformer?: (obj: any) => any;
		defaultValue?: any;
		fromLeaf?: boolean;
		field?: "data" | "handle";
	} = {},
) {
	// Default values
	const defaultOptions = {
		transformer: (i) => i,
		defaultValue: [],
		fromLeaf: true,
		field: "data",
	};

	const matches = useMatches();
	if (!matches) console.log({ thing, matches });
	// Merge options with default values
	const finalOptions = { ...defaultOptions, ...options };

	const clonedMatches = produce(matches, (draft) => {
		if (finalOptions.fromLeaf) draft.reverse();
	});

	const lastObjectWithData = clonedMatches.find(
		(obj) => obj?.[finalOptions.field]?.[thing] !== undefined,
	);
	const l =
		lastObjectWithData?.[finalOptions.field]?.[thing] ??
		finalOptions.defaultValue;
	if (l) {
		return finalOptions.transformer ? finalOptions.transformer(l) : l;
	}

	return l;
}
